import React from 'react'
// import { Link } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/Layout'
// import Container from '../components/Container'
import SEO from '../components/SEO'
import * as colors from '../components/colors'

const Container = styled.div`
  margin: 0 auto;
  max-width: 1600px;

  a, a:visited, a:active {
    color: ${colors.N850};
  }
`

const StyledSandbox = styled.div`
  __border: 1px dashed pink;
  margin: 0 80px 24px 80px;

  @media (max-width: 640px) {
    margin: 0;
  }

  .articles {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 48px;

    article {
      __background: pink;
      __min-height: 256px;
      grid-column: span 2;
    }

    
    .featured-article {
      grid-column: 1/-1;
      __padding-bottom: 64px;
      __border-bottom: 1px solid pink;

      a {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 48px;
        grid-auto-flow: dense;
      }

      .thumbnail {
        grid-column-end: span 2;
      }
    }  

    a {
      display: block;
      width: 100%;
    }

    .thumbnail {
      position: relative;
      background-color: #1f123f;
      background-image: url(https://via.placeholder.com/800x500);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      width: 100%;
      padding-top: 56.25%;
      padding-top: calc(500/800 * 100%);
      border: 1px solid blue;
 
    }

    .details {
      display: flex;
      flex-direction: column;

      p {
        margin: 0;
      }
    }

    @media (max-width: 960px) {
      article {
        grid-column: 1/-1;
      }
      .featured-article {
        padding-bottom: 0;

        a {
          display: block;
        }
      }
      .details {
        __margin: 24px 24px 24px 24px;
        margin: 24px 0;
      }
    }
  }
`

const Sandbox = () => {
  return (
    <Layout>
      <SEO title="Sandbox" />
      <Container>
        <StyledSandbox>    
          <section class="articles">
            <article class="featured-article">
              <a href="#todo">
                <div class="thumbnail" />
                <div class="details">
                  <div>TAG</div>
                  <h1>You thirsty?</h1>
                  <p>
                    Explore local breweries with just one click and stirred by starlight across the centuries 
                    light years great turbulent clouds circumnavigated paroxysm of global death.
                  </p>
                </div>
              </a>
            </article>
            <article>
              <a href="#todo">
                <div class="thumbnail" />
                <div class="details">
                  <div>TAG</div>
                  <h1>You thirsty?</h1>
                  <p>
                    Explore local breweries with just one click and stirred by starlight across the centuries 
                    light years great turbulent clouds circumnavigated paroxysm of global death.
                  </p>
                </div>
              </a>
            </article>
            <article>
              <a href="#todo">
                <div class="thumbnail" />
                <div class="details">
                  <div>TAG</div>
                  <h1>You thirsty?</h1>
                  <p>
                    Explore local breweries with just one click and stirred by starlight across the centuries 
                    light years great turbulent clouds circumnavigated paroxysm of global death.
                  </p>
                </div>
              </a>
            </article>
            <article>
              <a href="#todo">
                <div class="thumbnail" />
                <div class="details">
                  <div>TAG</div>
                  <h1>You thirsty?</h1>
                  <p>
                    Explore local breweries with just one click and stirred by starlight across the centuries 
                    light years great turbulent clouds circumnavigated paroxysm of global death.
                  </p>
                </div>
              </a>
            </article>
            {/* <article class="featured-article">
              <a href="#todo">
                <div class="thumbnail" />
                <div class="details">
                  <div>TAG</div>
                  <h1>You thirsty?</h1>
                  <p>
                    Explore local breweries with just one click and stirred by starlight across the centuries 
                    light years great turbulent clouds circumnavigated paroxysm of global death.
                  </p>
                </div>
              </a>
            </article> */}
            <article>
              <a href="#todo">
                <div class="thumbnail" />
                <div class="details">
                  <div>TAG</div>
                  <h1>You thirsty?</h1>
                  <p>
                    Explore local breweries with just one click and stirred by starlight across the centuries 
                    light years great turbulent clouds circumnavigated paroxysm of global death.
                  </p>
                </div>
              </a>
            </article>
            <article>
              <a href="#todo">
                <div class="thumbnail" />
                <div class="details">
                  <div>TAG</div>
                  <h1>You thirsty?</h1>
                  <p>
                    Explore local breweries with just one click and stirred by starlight across the centuries 
                    light years great turbulent clouds circumnavigated paroxysm of global death.
                  </p>
                </div>
              </a>
            </article>
          </section>
        </StyledSandbox>        
      </Container>
    </Layout>
  )
}

export default Sandbox
